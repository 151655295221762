import {useCallback, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import {useNavigate} from 'react-router-dom'
import {SelectOption} from '../../../components/Common'
import ActionButton from '../../../components/Common/ActionButton'
import {
  ROLE_TYPE,
  changeUserStatus,
  deleteUser,
  updateAdminApprovalStatus,
  userListing,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {getCharity, setCharity} from '../../modules/auth/core/StorageHelper'
import {toast} from 'react-toastify'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {useSessionState} from '../../../hooks/useSessionState'
import {debounce} from '../../../utils'

export interface CharitiesDonors {
  _id: string
  name: string
  email: string
  status: string
  profilePic: string
  isDeleted: boolean
  isProfileApproved: boolean
  isProfileRejected: boolean
  isProfilePending: boolean
}
export interface InitialState {
  loading: boolean
  charities: CharitiesDonors[]
  donors: CharitiesDonors[]
  currentPage: number
  totalPages: number
  error: null | any
  search: string
  changeStatusId?: null | string
  filters: {status: string}
  multipleDeleteModelView: boolean
  accountApproveView: boolean
  accountRejectView: boolean
  deleteLoading: boolean
  approveLoading: boolean
  accountApproveTitleLoading: boolean
  accountRejectTitleLoading: boolean
  totalActiveCharities: number
  totalInActiveCharities: number
  totalDeletedCharities: number
  totalPendingApproval: number
  totalRejectedApproval: number
}

const initialState = {
  loading: true,
  charities: [],
  donors: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  search: '',
  changeStatusId: null,
  multipleDeleteModelView: false,
  accountApproveView: false,
  accountRejectView: false,
  accountApproveTitleLoading: false,
  accountRejectTitleLoading: false,
  deleteLoading: false,
  approveLoading: false,
  filters: {
    status: '',
  },
  totalActiveCharities: 0,
  totalInActiveCharities: 0,
  totalDeletedCharities: 0,
  totalPendingApproval: 0,
  totalRejectedApproval: 0,
}
const Charity = () => {
  const navigate = useNavigate()
  const {auth, currentUser} = useAuth()
  const [state, setState] = useSessionState<InitialState>(initialState, 'charity')

  //delete the multiple charity
  const [selectedRow, setSelectedRows] = useState([])
  const [deleteValue, setDeleteValue] = useState(false)
  const [rowStatus, setRowStatus] = useState<string[]>([])
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRows(selectedRows.map((row) => row._id))
    setRowStatus(selectedRows.map((row) => row.status))
  }
  const id = getCharity()

  useEffect(() => {
    setDeleteValue(false)
  }, [deleteValue])

  const getCharities = useCallback(
    async (token, role, page, search, filters, pageSize) => {
      try {
        setState({...state, loading: true})
        const result = await userListing(token, role, page, search, filters, pageSize)

        if (result.status === 200) {
          setState({
            ...state,
            loading: false,
            search: search,
            charities: result.data.data.userList,
            currentPage: result.data.data.currentPage,
            totalPages: result.data.data.totalPages,
            totalActiveCharities: result.data.data.totalActiveUsers,
            totalInActiveCharities: result.data.data.totalInActiveUsers,
            totalDeletedCharities: result.data.data.totalDeletedUsers,
            totalPendingApproval: result.data.data.totalPendingUsers,
            totalRejectedApproval: result.data.data.totalRejectedUsers,
          })
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, error: error, loading: false})
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (auth && auth.token) {
      getCharities(
        auth?.token,
        ROLE_TYPE.CHARITY,
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
  }, [state.currentPage, state.search, state.filters, deleteValue, pageSize])

  const columns = [
    {
      name: 'Name',
      selector: (row: CharitiesDonors, i) => row.name,
      width: '290px',
      format: (row: CharitiesDonors, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div
              className='symbol symbol-50px me-5 cursor-pointer'
              onClick={() => {
                setCharity(row._id)
                navigate(row.name)
              }}
            >
              <img
                src={row.profilePic ? row.profilePic : toAbsoluteUrl('/media/avatars/blank.png')}
                className='h-[75px] w-[75px] symbol-label align-self-end'
                alt=''
              />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() => {
                  setCharity(row._id)
                  navigate(row.name)
                }}
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.name}
              >
                {row.name}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: CharitiesDonors, i) => row.email,
      width: '290px',
      format: (row: CharitiesDonors, i) => {
        return (
          <span
            className='text-dark fw-bold text-hover-primary fs-6 min-w-100px'
            onClick={() => {
              navigate(row.name)
              setCharity(row._id)
            }}
          >
            {row.email}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: CharitiesDonors, i) => row.status,
      minWidth: '130px',
      width: '200px',
      format: (row: CharitiesDonors, i) => {
        const isLoading = state.changeStatusId === row._id
        if (row.isDeleted) {
          return (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
                {'Deleted'}
              </span>
            </>
          )
        } else if (row.isProfileApproved === false && row.isProfilePending === true) {
          return (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3 fw-bolder pending'>
                {'Pending'}
              </span>
            </>
          )
        } else if (row.isProfileRejected === true && row.isProfileApproved === false) {
          return (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
                {'Rejected'}
              </span>
            </>
          )
        } else {
          if (isLoading) {
            return <span className='badge text-black p-4 badge-light'>Loading...</span>
          } else {
            return (
              <SelectOption
                options={[
                  {value: 'Active', title: 'Active'},
                  {value: 'InActive', title: 'Inactive'},
                ]}
                selected={row.status}
                onChange={async (e) => {
                  setState({...state, changeStatusId: row._id})

                  const value = e.target.value

                  try {
                    const result = await changeUserStatus(auth?.token, {
                      id: row._id,
                      status: value,
                      role: ROLE_TYPE.CHARITY,
                    })

                    if (result.status === 200) {
                      setState((prev) => ({
                        ...prev,
                        charities: prev.charities.map((charity, index) =>
                          index === i ? {...charity, status: value} : charity
                        ),
                        changeStatusId: null,
                      }))
                      window.location.reload()
                    } else {
                      toast(result.data.message)
                      setState({...state, changeStatusId: null})
                    }
                  } catch (error: any) {
                    toast(
                      error?.response?.data?.message || error?.message || 'Something went wrong!'
                    )
                    setState({...state, changeStatusId: null})
                  }
                }}
              />
            )
          }
        }
      },
      sortable: false,
    },
    {
      name: 'Action',
      selector: (row: CharitiesDonors, i) => row.status,
      minWidth: '105px',
      format: (row: CharitiesDonors, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() => {
                navigate(row.name)
                setCharity(row._id)
              }}
              editCallback={() => {
                setCharity(row._id)
                navigate(`${row.name}?edit=true`)
              }}
              approveCallback={() => {
                setCharity(row._id)
                setState({...state, accountApproveView: true})
              }}
              rejectCallback={() => {
                setCharity(row._id)
                setState({...state, accountRejectView: true})
              }}
              editDisabled={row.isDeleted === true}
              hideApproveRejectButtons={
                !row.isProfileApproved && !row.isDeleted && !row.isProfileRejected
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  console.log('state', state)
  return (
    <>
      <DataTableWrapper
        loading={state?.loading}
        filters={{
          dropdown: [
            {
              label: 'Status',
              options: ['Active', 'InActive', 'Deleted', 'Pending', 'Rejected'].map((t) => ({
                value: t,
                label: t.toLowerCase(),
              })),
              selected: state.filters.status,
            },
          ],
        }}
        filtersCallback={(filters) => {
          if (filters?.reset) {
            setState((prev) => ({
              ...prev,
              filters: initialState.filters,
              currentPage: initialState.currentPage,
              search: initialState.search,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              currentPage: initialState.currentPage,
              filters: {
                status: filters['Status']?.value || '',
              },
            }))
          }
        }}
        columns={columns}
        data={state?.charities}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => setState({...state, currentPage: page})}
        handleSearch={debouncedOnChange}
        onSelectedRowsChange={handleSelectedRowsChange}
        onDelete={() => setState({...state, multipleDeleteModelView: true})}
        selectableRows
        totalActiveCharity={state.totalActiveCharities}
        totalInActiveCharity={state.totalInActiveCharities}
        totalDeletedCharity={state.totalDeletedCharities}
        totalPendingApproval={state.totalPendingApproval}
        totalRejectedApproval={state.totalRejectedApproval}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        pageSize={pageSize}
      />

      {state.multipleDeleteModelView && (
        <CustomModal
          type={'DELETE'}
          body={selectedRow}
          deleteLoading={state.deleteLoading}
          title={true}
          handleConfirm={async () => {
            try {
              if (rowStatus.includes('InActive')) {
                toast('Cannot delete inactive charity.')
                setState({...state, multipleDeleteModelView: false})
                return
              }
              setState({...state, deleteLoading: true})
              const result = await deleteUser(currentUser?.token, selectedRow, 'charity')
              if (result.status === 200) {
                setDeleteValue(true)
                toast.success(result.data.message || 'Account Deleted Succesfully')
                setState({
                  ...state,
                  multipleDeleteModelView: false,
                  deleteLoading: false,
                })
                setSelectedRows([])
              }
            } catch (error: any) {
              setState({
                ...state,
                multipleDeleteModelView: false,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || 'Something went wrong!')
            }
            setSelectedRows([])
          }}
          handleClose={() => {
            setState({...state, multipleDeleteModelView: false})
            setSelectedRows([])
          }}
        />
      )}

      {state.accountApproveView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.ACCOUNT_APPROVAL}
          body={selectedRow}
          approveLoading={state.approveLoading}
          accountApproveTitle={true}
          handleConfirm={async () => {
            try {
              setState({...state, approveLoading: true})
              const result = await updateAdminApprovalStatus(currentUser?.token, id, 'approved', '')
              if (result.status === 200) {
                setDeleteValue(true)
                toast.success(result.data.message || 'Charity Approved Succesfully')
                setState({
                  ...state,
                  accountApproveView: false,
                  approveLoading: false,
                })
              }
            } catch (error: any) {
              setState({
                ...state,
                accountApproveView: false,
                approveLoading: false,
              })
              toast(error?.response?.data?.message || 'Something went wrong!')
            }
          }}
          handleClose={() => {
            setState({...state, accountApproveView: false})
          }}
        />
      )}

      {state.accountRejectView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.ACCOUNT_APPROVAL}
          body={selectedRow}
          approveLoading={state.approveLoading}
          accountRejectTitle={true}
          handleConfirm={async (rejectReason) => {
            try {
              setState({...state, approveLoading: true})
              const result = await updateAdminApprovalStatus(
                currentUser?.token,
                id,
                'rejected',
                rejectReason
              )
              if (result.status === 200) {
                setDeleteValue(true)
                toast.success(result.data.message || 'Charity Rejected Succesfully')
                setState({
                  ...state,
                  accountRejectView: false,
                  approveLoading: false,
                })
              }
            } catch (error: any) {
              setState({
                ...state,
                accountRejectView: false,
                approveLoading: false,
              })
              toast(error?.response?.data?.message || 'Something went wrong!')
            }
          }}
          handleClose={() => {
            setState({...state, accountRejectView: false})
          }}
        />
      )}
    </>
  )
}

const CharityWrapper = () => (
  <>
    <PageTitle>Charity Management</PageTitle>
    <Charity />
  </>
)

export {CharityWrapper}
